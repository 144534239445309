
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Image from 'next/image'
import BookMockup from '../../public/images/engineering-management-for-the-rest-of-us-book-mockup-perspective@2x.png'



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A lot of Engineering Managers and leaders studied for years and years to become the best Engineer they possibly could be… and then they were promoted.`}</p>
    <p>{`It can be very tough for those of us who didn’t go into Engineering with the distinct concept that we would become managers, but still want to do our best to support our teams.`}</p>
    <strong className="font-din uppercase text-center lg:-mx-20 mx-auto lg:text-[2.7rem] sm:text-5xl text-4xl tracking-wide block md:py-16 sm:py-16 py-10 text-orange-200">
  <span>This book isn’t for the “born leaders”.</span>
  <span className="text-orange-300 block">
    This book is for the rest of us.
  </span>
    </strong>
    <p>{`I wrote this book because there’s so much no one told me about management that I wished I would have known. There’s a lot to be purposeful about that many of us learn on the job, and worse: learn on people. This book provides some organization for collaborating with networks of people, working together towards a common purpose.`}</p>
    <strong className="font-din uppercase text-center lg:text-[2.7rem] sm:text-5xl text-4xl tracking-wide inline-block md:pt-16 sm:pt-16 pt-10 sm:pb-10 pb-4 text-indigo-100">
  I wrote the book I wished I had{' '}
  <span className="text-indigo-200 sm:inline-block">
    when I was getting started.
  </span>
    </strong>
    <figure className="lg:px-24 sm:px-16 px-10 py-5">
  <Image src={BookMockup} palceholder="blur" alt="Book mockup of Engineering Management for the Rest of Us" quality={90} mdxType="Image" />
    </figure>
    <p>{`There seem to be millions of articles and “how to”s on programming and only a handful of resources on Engineering Management- why? It’s very tough to talk about something that involves people processes. People are non-deterministic. Working relationships are nuanced, communication is linked with individual values, motivations, power dynamics, and skills. People also have a range of experiences and emotions that are not consistent day-to-day.`}</p>
    <strong className="font-din uppercase text-center lg:text-[2.7rem] sm:text-5xl text-4xl tracking-wide inline-block md:py-16 sm:py-16 py-10 text-indigo-100">
  Yet we all need to figure out{' '}
  <span className="text-orange-300 sm:inline-block">how to work together.</span>
    </strong>
    <p>{`Hopefully, in the happiest, most productive sense.`}</p>
    <p>{`It’s imperative that we as managers learn as much as we can and work on ourselves, so that our teams may enjoy a healthy working life and strong relationships. It’s not just important, it’s crucial that we iterate on our own skills as managers so that we can properly support everyone around us: individuals, peers, leadership, and the business.`}</p>
    <p>{`I’m sharing what I’ve learned- not so that you follow my concepts exactly, but rather so that you can be thoughtful about your own leadership and needs. The book goes from the macro to the micro- with topics ranging everywhere from “feedback” to “scoping down PRs”.`}</p>
    <p>{`Though the book is meant to address people in management, individual contributors are welcome to read the book as well- perhaps you need to manage up and need some tools to help guide the conversation, perhaps you just want a peek at other concerns within the business- everyone is invited to the conversation.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;